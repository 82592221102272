
.quill .ql-editor iframe.ql-video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.quill .ql-editor audio {
  display: block;
  margin: auto;
}

.quill .ql-editor audio:focus-visible {
  outline: none;
}

.react-calendar-heatmap text {
  font-size: 8px !important;
  fill: #000 !important;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

.react-calendar-heatmap rect:focus {
  stroke: #555;
  stroke-width: 1px;
  outline: 0;
}

/*
 * Default color scale
 */

.calendar-heatmap-container .color-empty,
.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.calendar-heatmap-container .color-filled,
.react-calendar-heatmap .color-filled {
  fill: #8cc665;
}

/*
 * Color scale
 */

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.calendar-heatmap-container .color-level-0,
.react-calendar-heatmap .color-level-0 {
  fill: #ededed;
}

.calendar-heatmap-container .color-level-1,
.react-calendar-heatmap .color-level-1 {
  fill: #e4f2ff;
}


.calendar-heatmap-container .color-level-2,
.react-calendar-heatmap .color-level-2 {
  fill: #679ef9;
}

.calendar-heatmap-container .color-level-3,
.react-calendar-heatmap .color-level-3 {
  fill: #4d8ef6;
}

.calendar-heatmap-container .color-level-4,
.react-calendar-heatmap .color-level-4 {
  fill: #357ff7;
}

.pie-chart-large .recharts-default-legend .recharts-legend-item {
  display: inline-flex !important;
  align-items: center;
}

.pie-chart-large .recharts-default-legend {
  height: 80px;
}

.pie-chart-mobile {
  height: auto;
}

@primary-color: #57039A;