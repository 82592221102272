.sidebar-page {
    margin-top: 20px;
      
    .sider-left {
        background: #fff;
        margin-left: 20px;
    } 

        .sider-left {
        background: #fff;
        margin-left: 20px;
    }

    .filter {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      margin-top: 8px;
    }

    .filter-item {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 400;
        margin-top: 10px;
    }
    .longform {
      .ant-card-body {
        padding: 10px;
      }
    }
}

@primary-color: #57039A;