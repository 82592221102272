.admin-layout {
    .header {
        background: #fff;
        padding: 0 20px;
        .logo {
            height: 36px;
            padding-top: 0px;
            border-radius: 5px;
        }
    }
    .sider-layout-background {
        background: #fff;
        margin-top: 20px;
        margin-left: 20px;
    }
}
@primary-color: #57039A;