.jobs-page {
    .ql-editor {
        min-height: 200px;
    }

    .ant-statistic-content-value-int {
        font-size: 15px;
    }

    .site-badge-count-4 .ant-badge-count {
    background-color: #fff;
    color: #999;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
    }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.pie-chart-large {
    display: block;
    height: 400px !important;
}

.pie-chart-large .recharts-default-legend .recharts-legend-item {
    display: inline-flex !important;
    align-items: center;
}

.pie-chart-large .recharts-default-legend {
    height: 80px;
}

// .pie-chart-mobile {
//     display: none;
//     height: 400px !important;
//     // height: auto;
// }

// @media (min-width: 640px) {
//     .pie-chart-large {
//         display: none;
//     }

//     .pie-chart-mobile {
//         display: block;
//     }
// }

// @media (min-width: 768px) {
//     .pie-chart-large {
//         display: block;
//     }

//     .pie-chart-mobile {
//         display: none;
//     }
// }

// @media (min-width: 1024px) {
//     .pie-chart-large {
//         display: none;
//     }

//     .pie-chart-mobile {
//         display: block;
//     }
// }

// @media (min-width: 1280px) {
//     .pie-chart-large {
//         display: block;
//     }

//     .pie-chart-mobile {
//         display: none;
//     }
// }

.custom-tooltip {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 12px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding-bottom: 0 !important;
}

// .custom-tooltip .label {
    // background: red;
// }
@primary-color: #57039A;